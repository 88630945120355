
export default {
  layout: "clean",
  data() {
    return {
      seconds: 8,
    }
  },
  meta: {
    public: true,
  },
  head() {
    return {
      title: "Evercam | Good Bye",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  mounted() {
    this.countdown()
  },
  methods: {
    countdown() {
      if (this.seconds > 0) {
        this.seconds = this.seconds - 1
        this.$setTimeout(() => this.countdown(this.seconds - 1), 1000)
      } else {
        this.$router.push("/v2/users/signin")
      }
    },
  },
}
